<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">用户管理</div>
        <el-button
          v-if="chuangjian"
          type="primary"
          @click="addIdType(0, null)"
          class="addIdType"
          >+ 创建用户</el-button
        >
      </div>
    </div>
    <div class="searchAll">
      <el-card>
        <!-- 一个表单 -->
        <el-form :inline="true" :model="condition">
          <el-form-item label="账号:">
            <el-input
              v-model="condition.userCode"
              placeholder="请输入账号"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名:">
            <el-input
              ref="welcome"
              v-model="condition.userName"
              placeholder="请输入姓名"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话:">
            <el-input
              ref="remark"
              maxlength="100"
              v-model="condition.phone"
              placeholder="请输入电话"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="addIdType2" type="primary" @click="onSearch()"
              >查询</el-button
            >
            <el-button class="addIdType3" type="primary" @click="reSearch()"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>

    <div class="hl-content">
      <!-- <div class="mokuai">用户管理</div> -->
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
        :default-sort="{ prop: 'sort' }"
      >
        <el-table-column
          label="序号"
          type="index"
          width="80px"
        ></el-table-column>
        <el-table-column label="账号 ">
          <template slot-scope="scope">
            <span>{{ scope.row.userCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="电话" width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="部门">
          <template slot-scope="scope">
            <span>{{ scope.row.deptCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态 ">
          <template slot-scope="scope">
            <div class="zt">
              <div v-if="scope.row.userStatus == 0">
                <span class="qiyong"></span>
                <span>正常</span>
              </div>
              <div v-else>
                <span class="jinyong"></span>
                <span>删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="超级管理员 ">
          <template slot-scope="scope">
            <div class="zt">
              <div v-if="scope.row.superAdmin == 1">
                <span class="qiyong"></span>
                <span>是</span>
              </div>
              <div v-else>
                <span class="jinyong"></span>
                <span>否</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button v-if="xiugai" type="text" @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  class="cz-button"
                  type="text"
                  v-if="xiugai"
                  @click="reset(scope.row)"
                  >重置密码</el-button
                >
                <el-button
                  class="reset-button"
                  size="mini"
                  v-if="qxpz && scope.row.superAdmin != 1"
                  @click="quanxian(scope.row)"
                  >权限配置</el-button
                >
                <el-button
                  class="reset-button"
                  size="mini"
                  v-if="sjqx && scope.row.superAdmin != 1"
                  @click="sjquanx(scope.row)"
                  >数据权限</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.offset"
          :page-size="condition.num"
          layout=" prev, pager, next, jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>
    </div>
    <!-- 权限 -->
    <el-dialog
      title="权限配置"
      :visible.sync="qxVisible"
      v-if="qxVisible"
      width="45%"
      @close="closeDialog"
    >
      <el-tree
        :data="dataModeCheck"
        :props="modeProps"
        default-expand-all
        show-checkbox
        node-key="id"
        :default-expanded-keys="idList"
        :default-checked-keys="idList"
        @check="currentChecked"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button class="anniu" @click="qxVisible = false">取 消</el-button>
        <el-button
          class="anniu"
          v-if="saveqxpz"
          type="primary"
          @click="savequanxian"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 数据权限 -->
    <el-dialog title="数据权限" :visible.sync="sjVisible" width="45%">
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          v-for="item in hotelList"
          :key="item.id"
          :value="item.id"
          :label="item.id"
          >{{ item.hotelName }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button class="anniu" @click="sjVisible = false">取 消</el-button>
        <el-button
          class="anniu"
          v-if="savesjqx"
          type="primary"
          @click="choseHotel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <addModify ref="addModify" />
    <disableModify ref="disableModify" />
  </div>
</template>
<script>
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import addModify from "@/views/user/addModify.vue";
import disableModify from "@/views/user/disableModify.vue";

export default {
  data() {
    return {
      sjVisible: false,
      qxVisible: false,
      tableData: [],
      input: "",
      totolNums: 0,
      modeCode: null,
      status: 0,
      condition: {
        userCode: "",
        userName: "",
        phone: "",
        offset: 1,
        num: 10,
      },
      userCode: null,
      infor: {},
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      qxpz: false,
      saveqxpz: false,
      sjqx: false,
      savesjqx: false,
      hotelList: [],
      checkList: [],
      dataModeCheck: [],
      modeProps: {
        children: "children",
        label: "moduleName",
      },
      sureList: [],
      chooseList: [],
      idList: [],
    };
  },
  components: {
    addModify,
    disableModify,
  },
  created() {
    if (!this.power.checkedpower("0103004")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // // 创建
    this.chuangjian = this.power.checkedpower("0103001");
    // // 修改
    this.xiugai = this.power.checkedpower("0103002");
    // // 删除，禁用
    this.shanchu = this.power.checkedpower("0103003");
    this.qxpz = this.power.checkedpower("0103005");
    this.saveqxpz = this.power.checkedpower("0103006");

    this.sjqx = this.power.checkedpower("0103007");
    this.savesjqx = this.power.checkedpower("0103008");
    this.infor = this.$route.query;
    this.condition.modeCode = this.infor.code;
    this.getFounderList();
    this.getHotelList();
    this.allQuanxian();
  },
  methods: {
    reSearch() {
      this.condition = {
        userCode: "",
        userName: "",
        phone: "",
        offset: 1,
        num: 10,
      };
      this.getFounderList();
    },
    onSearch() {
      this.getFounderList();
    },
    // 获取权限列表数据
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysUser", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.tableData = res.data.list;
          this.totolNums = res.data.total;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 序号
    table_index(index) {
      return (this.condition.offset - 1) * this.condition.offset + index + 1;
    },
    // 权限配置
    quanxian(row) {
      this.idList = [];
      this.userCode = row.userCode;
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        userCode: row.userCode,
      };
      this.userCode = row.userCode;
      postRequest("/selectAllSysUserRight", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.idList = [];
          this.qxVisible = true;
          this.chooseList = res.data;
          this.sureList = res.data;
          if (this.chooseList.length > 0) {
            this.dataModeCheck.forEach((item) => {
              item.children.forEach((item1) => {
                item1.rights.forEach((item2) => {
                  this.chooseList.forEach((itemClick) => {
                    if (item2.rightCode == itemClick.rightCode) {
                      item2.id = itemClick.id;
                      this.idList.push(itemClick.id);
                      // this.$nextTick(function () {
                      //   //Dom更新完毕
                      //   this.$refs.tree.setCheckedKeys(this.idList);
                      // });
                    }
                  });
                });
              });
            });
          } else {
            this.idList = [];
            // this.$nextTick(function () {
            //   //Dom更新完毕
            //   this.$refs.tree.setCheckedKeys(this.idList);
            // });
          }
          console.log(678, this.idList);
          console.log(996, this.dataModeCheck);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    currentChecked(nodeObj, SelectedObj) {
      let list = SelectedObj.checkedNodes;
      let lis1 = [];
      list.forEach((item) => {
        if (item.rightCode) {
          lis1.push(item);
        }
      });

      this.dataModeCheck.forEach((item) => {
        item.children.forEach((item1) => {
          item1.children.forEach((item2) => {
            lis1.forEach((item3) => {
              if (item2.rightCode == item3.rightCode) {
                item3.moduleNo = item1.moduleNo;
                item3.parentModuleNo = item.parentModuleNo;
                item3.userCode = this.userCode;
              }
            });
          });
        });
      });
      console.log(7777, lis1);
      this.sureList = lis1;
    },

    savequanxian() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = [];
      if (this.sureList.length > 0) {
        parms = this.sureList;
      } else {
        parms = [{ userCode: this.userCode }];
      }

      postRequest("/updateSysUserRight", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.qxVisible = false;

          this.$message({
            duration: 5000,
            message: res.message,
            type: "success",
            hasClose: true,
          });
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    // 查所有权限
    allQuanxian() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {};
      postRequest("/getMySysModule", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.moduleName = item.parentModuleName;
            item.children.forEach((item1) => {
              item1.children = item1.rights;
              item1.children.forEach((item2) => {
                item2.moduleName = item2.rightName;
              });
            });
          });
          this.dataModeCheck = res.data;
          console.log(this.dataModeCheck);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 数据权限
    sjquanx(row) {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      this.userCode = row.userCode;
      let parms = {
        userCode: this.userCode,
      };
      postRequest("/selectSysDataRight", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.sjVisible = true;
          this.checkList = [];
          console.log(1, this.hotelList);
          // return;
          this.hotelList.forEach((item) => {
            res.data.forEach((item2) => {
              if (item.id == item2.hotelId) {
                this.checkList.push(item.id);
              }
              console.log(222, this.checkList);
            });
          });

          // this.checkList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    // 数据权限配置新增
    choseHotel() {
      console.log(111, this.checkList);
      let list = [];
      this.checkList.forEach((item) => {
        list.push({ hotelId: item, userCode: this.userCode });
      });
      this.hotelList.forEach((item) => {
        list.forEach((item1) => {
          console.log(item, item1);
          if (item.id == item1.hotelId) {
            item1.dispOrder = item.dispOrder;
          }
        });
      });
      if (list.length == 0) {
        list = [
          {
            userCode: this.userCode,
          },
        ];
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("/updateSysDataRight", list).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.sjVisible = false;
          this.$message({
            duration: 5000,
            message: res.message,
            type: "success",
            hasClose: true,
          });
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    disable(index, row) {
      let state = "禁用";
      let modecode = this.infor.code;
      this.$refs.disableModify.show(state, row, modecode);
    },
    // 修改
    edit(row) {
      let state = "编辑";
      this.$refs.addModify.show(state, this.infor, row);
    },
    reset(row) {
      let state = "重置密码";
      this.$refs.disableModify.show(state, this.infor, row);
    },
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    hotel() {
      this.$router.push("/hotel");
    },
    changePage(val) {
      this.condition.offset = val;
      this.getFounderList();
    },
    // 每页的数据数
    onPaginationSize(val) {
      this.condition.num = val;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(val) {
      this.condition.offset = val;
      this.getFounderList();
    },
    closeDialog() {
      this.$router.push("/user");
      // this.$router.go(0);
      // this.$nextTick(function () {
      //   //Dom更新完毕
      //   this.$refs.tree.setCheckedKeys([]);
      // });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.addIdType3 {
  width: 80px;
  height: 40px;
  color: #fff;
  background: rgb(151, 151, 151);
  border: rgb(151, 151, 151);
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
  overflow: scroll;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.reset-button {
  width: 74px;
  height: 28px;
  background: #409eff;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.anniu {
  height: 30px;
  width: 80px;
}
.el-table {
  height: 90%;
  overflow: hidden;
}
.block {
  position: absolute;
  bottom: 10px;
  right: 32px;
}
.searchAll {
  margin-bottom: 20px;
}
</style>